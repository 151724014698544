<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-memberCall">
          <div id="video" class="l-memberCall_video"></div>
          <div v-if="phase === 0">
            <div class="l-memberCall_txt">
              <div v-if="name !== '---'">{{ name }} さん</div>
              受付より着信中です
            </div>
            <div class="l-memberCall_btn" v-if="canCancel">
              <div @click="cancel">
                <img
                  class="l-memberCall_btn_img"
                  src="@/assets/img/tel-hangup.svg"
                />
              </div>
              <div @click="connect">
                <img
                  class="l-memberCall_btn_img"
                  src="@/assets/img/tel-answer.svg"
                />
              </div>
            </div>
          </div>
          <div v-if="phase === 1">
            <p class="l-memberCall_txt">通話中</p>
            <div class="l-memberCall_btn l-memberCall_btn-single">
              <div @click="disconnect">
                <img
                  class="l-memberCall_btn_img"
                  src="@/assets/img/tel-hangup.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <video
      id="me"
      style="position:fixed;bottom:-300vh;right:-300vh;width:300px;height:300px;z-index:-1;opacity:0"
      autoplay
      muted
    ></video>
  </div>
</template>

<script>
import axios from "axios";
import { connect } from "twilio-video";

var _room;

export default {
  name: "MemberCall",
  data: () => ({
    phase: 0,
    name: "---",
    isVisitor: false,
    canCancel: false,
  }),
  async mounted() {
    try {
      document.getElementById("video").textContent = null;

      window.addEventListener("beforeunload", () => _room.disconnect());

      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: true,
        })
        .then((stream) => {
          const video = document.getElementById("me");
          video.srcObject = stream;
        }).catch((e) => {
          log("[navigator.mediaDevices] " + e); // eslint-disable-line
        });

      if (this.$route.query.jeki !== "1") {
        const companies = await axios.get("/companies.json");
        const member = companies.data
          .filter((x) => x.id === this.$route.query.target)[0]
          .members.filter((x) => String(x.id) === this.$route.query.member)[0];
        this.name = member.ja;
      }

      const { data } = await axios.get("/token.php");

      connect(data.token, {
        name: "VideoRoom"
      }).then((room) => {
        log(JSON.stringify(room)); // eslint-disable-line

        room.localParticipant.audioTracks.forEach((t) => {
          t.track.disable();
        });

        _room = room;

        room.participants.forEach(this.participantConnected);
        room.on("participantConnected", this.participantConnected);
        room.on("participantDisconnected", this.participantDisconnected);
      }).catch((e) => {
        log("[connect] " + e); // eslint-disable-line
      });

      setTimeout(
        function() {
          if (this.isVisitor) return;
          this.disconnect();
        }.bind(this),
        5000
      );
    }
    catch (e) {
      log(e); // eslint-disable-line
    }
  },
  methods: {
    async connect() {
      notify_call.pause(); // eslint-disable-line
      notify_call.currentTime = 0; // eslint-disable-line

      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "cc",
        })
      );

      _room.localParticipant.audioTracks.forEach((t) => {
        t.track.enable();
      });

      this.phase++;
    },
    disconnect() {
      notify_call.pause(); // eslint-disable-line
      notify_call.currentTime = 0; // eslint-disable-line

      state = "end"; // eslint-disable-line

      _room.disconnect();
      this.$router.push("/member_top");
    },
    async cancel() {
      notify_call.pause(); // eslint-disable-line
      notify_call.currentTime = 0; // eslint-disable-line

      _room.disconnect();
      await axios.post(
        "/status.php",
        JSON.stringify({
          s: "cr",
        })
      );
      this.$router.push("/member_top");
    },
    participantConnected(rp) {
      log(JSON.stringify(rp)); // eslint-disable-line

      this.isVisitor = true;
      this.canCancel = true;

      console.log("participantConnected", rp);

      rp.on("trackSubscribed", this.onTrackSubscribed);

      rp.tracks.forEach((rtp) => {
        if (!rtp.isSubscribed && !rtp.track) return;

        this.attachTrack(rtp.track);
      });

      notify_call.play(); // eslint-disable-line
    },
    onTrackSubscribed(t) {
      this.attachTrack(t);
    },
    attachTrack(t) {
      log(JSON.stringify(t)); // eslint-disable-line
      console.log("attachTrack", t);
      document.getElementById("video").appendChild(t.attach());
    },
    participantDisconnected() {
      this.disconnect();
    },
  },
};
</script>

<style scoped lang="scss">
.l-memberCall_video {
  position: relative;

  video {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
